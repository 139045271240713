(() => {
    let $ = jQuery;
    let cookie_to_be_checked = "accepted_cookies";
    let $cookie_policy = $('.cookie-policy-popup');
    let $action_accept = $cookie_policy.find('.cookie-policy-popup__action-close');
    // let $learn_more = $cookie_policy.find('.cookie-policy-popup__action-link');

    setTimeout(checkCookieStatus, 2000);

    // For debugging, add `?action=resetcookiepolicy` to reset cookie policy response
    const urlParams = new URLSearchParams(window.location.search);
    const actions = (urlParams.get('action') || "").split(',');
    if (actions.indexOf("resetcookiepolicy") != -1) {
        eraseCookie('accepted_cookies')
    }

    $action_accept.click((e) => {
        e.preventDefault();
        setCookie('accepted_cookies', true, 30)
        getCookie('accepted_cookies')
        $cookie_policy.addClass('cookie-policy-popup--accepted')
    })
    /*
     * Cookie handlers
     */
    function setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    function getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    function eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    function checkCookieStatus(){
        let settedCookie = getCookie(cookie_to_be_checked);
        if(settedCookie === null){
            $cookie_policy.addClass('show');
        }
    }
})()