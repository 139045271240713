/*
 * Accordion
 * https://codepen.io/aaronbushnell/pen/eGVdzv
 */
class Accordion {
    constructor($el) {
      this.$el = $el;
      this.$title = this.$el.querySelector('[data-title]');
      this.$content = this.$el.querySelector('[data-content]');
      this.isOpen = false;
      this.height = 0;
  
      this.events();
      this.close();
    }
  
    events() {
      this.$title.addEventListener('click', this.handleClick.bind(this));
      this.$content.addEventListener('transitionend', this.handleTransition.bind(this));
    }
  
    handleClick() {
      this.height = this.$content.scrollHeight;
  
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    }
  
    close() {
      this.isOpen = false;
      this.$el.classList.remove('is-active');
      this.$content.style.maxHeight = `${this.height}px`;
  
      setTimeout(() => {
        this.$content.style.maxHeight = `${0}px`;
      }, 1);
    }
  
    open() {
      this.isOpen = true;
      this.$el.classList.add('is-active');
      this.$el.classList.remove('is-hidden');
      this.$content.style.maxHeight = `${0}px`;
  
      setTimeout(() => {
        this.$content.style.maxHeight = `${this.height}px`;
      }, 1);
    }
  
    handleTransition() {
      if (!this.isOpen) {
        this.$el.classList.add('is-hidden');
      }
  
      this.$content.style.maxHeight = '';
    }
}

/*
 * Accordion filter
 */
class AccordionFilter {
    constructor($el) {
      this.$el = $el;
      this.$buttons = this.$el.querySelectorAll('[data-filter-button]');
      this.$filterable = document.querySelectorAll('[data-filterby]');

      // Defaults
      Array.from(this.$filterable).forEach(elem => elem.classList.add('is-filtered'));
  
      this.filterList = [];

      this.events();
    }
  
    events() {
        this.$buttons.forEach(button => button.addEventListener('click', this.handleClick.bind(this)))
    }

    handleClick(e) {
        e.preventDefault();
        let filterable = $('[data-filterby]');
        let filtered = $('[data-filterby="'+e.target.dataset.filter+'"]');

        // Button classes
        Array.from(filterable).forEach(button => button.classList.remove('filtered'));
        e.target.classList.toggle('filtered')

        // Filtered array
        var index = this.filterList.indexOf(e.target.dataset.filter);
        if (index !== -1) {
            this.filterList.splice(index, 1);
        } else {
            this.filterList.push(e.target.dataset.filter)
        }

        this.filter()
    }

    filter() {

        let filtered = []
        let unfiltered = []
        
        if (this.filterList.length == 0) {
            // Unfiltered
            this.show(this.$filterable)
            return;
        } else {
            // Filtered
            this.$filterable.forEach(elem => {
                var index = this.filterList.indexOf(elem.dataset.filterby);
                (index !== -1 ? filtered : unfiltered).push(elem)
            })
        }

        this.show(filtered);
        this.hide(unfiltered);
    }

    show(elems) {
        elems.forEach(elem => {
            if (elem.classList.contains('is-filtered')) {
                return;
            }
            // Classes
            elem.classList.remove('is-unfiltered')
            elem.classList.add('is-filtered')
            // Restore natural height
            let inner = elem.querySelector('.quec_accordion__inner');
            elem.style.height = inner.clientHeight + "px";
            // Restore natural heights
            elem.addEventListener('transitionend', (elem) => {elem.target.style.removeProperty('height')}, { once: true });
        })
    }

    hide(elems) {
        elems.forEach(elem => {
            if (elem.classList.contains('is-unfiltered')) {
                return;
            }
            // Classes
            elem.classList.remove('is-filtered')
            elem.classList.add('is-unfiltered')
            // Set current height
            let inner = elem.querySelector('.quec_accordion__inner');
            elem.style.height = inner.clientHeight + "px";
            // Add a delay so initial height is actually pre-render
            setTimeout(() => {
                // Height
                elem.style.height = '0px';
            }, 1)
        })
    }
}

/*
 * Instantiate
 */
jQuery(document).ready(function($) {

    const Accordions = document.querySelectorAll('.quec_accordion');
    const AccordionFilters = document.querySelectorAll('.quec_accordion_filter');
    
    if (Accordions.length > 0) {
        Accordions.forEach(accordion => {
            new Accordion(accordion);
        });
    }
    
    if (AccordionFilters.length > 0) {
        AccordionFilters.forEach(filter => {
            new AccordionFilter(filter);
        });
    }
});

// .quec_accordion_filter
