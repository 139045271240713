import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName('splide_slider_horizontal_post');

for (var i = 0; i < elms.length; i++) {
	new Splide(elms[i], {
		perPage: 4,
		gap: 33,
		pagination: false,

		breakpoints: {
			1024: {
				perPage: 3,
			},
			640: {
				perPage: 2,
			},
			480: {
				perPage: 1,
			},
		},
	}).mount();
}
