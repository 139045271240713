/* 
 * Quantity button in mini cart
 */
jQuery('.td-quantity-button').on('click', function () {
    let $ = jQuery;
    let $this = $(this);
    let $input = $this.parent().find('input');
    let $quantity = $input.val();
    let $new_quantity = 0;
    let step = parseInt($input[0].step) || 1;
    if ($this.hasClass('plus')) {
        $new_quantity = parseFloat($quantity) + step;
    } else {
        if ($quantity > 0) {
            $new_quantity = parseFloat($quantity) - step;
        }
    }
    $input.val($new_quantity);
});