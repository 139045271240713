var anchors = document.querySelectorAll('a[href^="#"]');
for (var i = 0; i < anchors.length; i++) {
  var anchor = anchors[i];
  var offset = 30;

  anchor.addEventListener('click', function(event) {
    var distance = document.querySelector(this.getAttribute('href')).getBoundingClientRect().top;
    event.preventDefault();
    window.location.hash = this.getAttribute('href');
    window.scrollTo({
      top: distance - offset,
      behavior: 'smooth',
    })
  });
}