/*
* Store filter display 
*/
jQuery(document).ready(() => {
    let $ = jQuery;
    let $container = $(".shop-container");
    let $wrapper = $(".sidebar-filters-container");
    let $toggle = $('.sidebar-header');
    let $content = $('.sidebar-filters-content');
    let $close_button = $content.find('.close-button');
    let $widget_wrappers = $('.shop-filters-widget-container');
    // Toggle hide / show filter window
    $toggle.click((event) => {
        $content.toggleClass('active');
        $container.toggleClass('filters-active');
        $('body').toggleClass('no-scroll');
        $('html').toggleClass('no-scroll');
        jQuery([document.documentElement, document.body]).animate({
            // scrollTop: $(".shop-container").offset().top
            scrollTop: $(document.body).offset().top
        }, 200);
    })
    // Close filter window
    $close_button.click((event) => {
        event.preventDefault();
        $container.removeClass('filters-active');
        $('body').toggleClass('no-scroll');
        $('html').toggleClass('no-scroll');
        $content.removeClass('active');
    })
    $widget_wrappers.each((i, target) => {
        let $clicker = $(target).find('h2');
        $clicker.click((event) => {
            $(target).toggleClass('collapsed');
        })
    })
})

/*
* Autosubmit search
*/
document.addEventListener("DOMContentLoaded", function() {
    const sidebars = document.querySelectorAll('.wpfMainWrapper');

    // keyCode of all keys that trigger the search
    const keys = [
        8, 9, 13, 32, 48, 49,
        50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
        60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
        70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
        80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
        90, 96, 97, 98, 99,
        100, 101, 102, 103, 104, 105, 106, 107, 109,
        110, 111, 186, 187, 188, 189,
        190, 191, 192, 219, 220, 221, 222
    ];
    if (sidebars.length > 0) {
        Array.from(sidebars).map((sidebar) => {
            const input = sidebar.querySelector('.wpfSingleInputSearch input[type=text]');
            const submit = input.nextElementSibling;
                
            input.addEventListener('keyup', function(event) {
                if (keys.includes(event.keyCode)) {
                    submit.click();
                }
            })
        })
    }
});

/*
* Store filters triggers (back button)
*/
jQuery(document).ready(() => {
    // console.log(ixwpsf.productFilter)
});