
/* 
 * Quantity button on checkout page
 */
var timeout;
jQuery(function ($) {
    $('.woocommerce').on('change', 'input.qty', function () {
        console.log('change')
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(function () {
            $("[name='update_cart']").trigger("click");
        }, 1000); // 1 second delay, half a second (500) seems comfortable too

    });
});

/*
 * Checkout - Handle updates and post
 */
jQuery(document).ready(() => {

    // Selectors
    let $ = jQuery;
    let $form = $('.woocommerce-cart-form');
    let $hidden_form = $('.checkoutbydigikey');
    let $widget = $('.wc-minicart-widget');
    let $total = $widget.find('span.total-items');
    let $widget_qty_text = $widget.find('span');
    let $cart_qty_text = $('.woocommerce').find('.cart-total');
    let $items = $form.find('.cart-item');

    // Get basket total from checkout inputs
    let get_total = () => {
        let $qtys = $form.find('.quantity').find('.input-text');
        let total = 0;
        $qtys.each((i, elem) => {
            total += parseInt($(elem).val())
        })
        return total;
    }

    // Update HTML 
    let update_qty = (i, e) => {
        let $input = $(e.target).parent().find('.input-text');
        let min = $input[0].min;
        let step = $input[0].step;
        let value = $input[0].value;
        // Don't go below minimum
        $input[0].value = value < min ? min : value;
        // Get total of all items
        let total = get_total();
        // Update page text
        $widget_qty_text.text(total)
        $cart_qty_text.text(total)
        // Find hidden field
        let $hidden = $hidden_form.find('.product-quantity');
        let hidden_field = $hidden.find(">:first-child")[i];
        let hidden_input = $hidden_form.find('.quantity').find('input')[i];
        // Update hidden fields
        hidden_field.value = $input[0].value;
        hidden_input.value = $input[0].value;
        // Update minicart
        update_minicart();
    }

    // Update the minicart
    let update_minicart = () => {
        console.log('update minicart')
        if (timeout !== undefined) {
            clearTimeout(timeout);
        } else {
            let timeout;
        }

        timeout = setTimeout(function () {
            // Update Mini Cart
            jQuery.post(
                woocommerce_params.ajax_url,
                { 'action': 'mode_theme_update_mini_cart' },
                function (response) {
                    $('#mode-mini-cart').html(response);
                }
            );
        }, 1000);
    }

    // Quantity change via click
    $items.each((i, elem) => {
        let $qty = $(elem).find('.quantity');
        let $btn_minus = $qty.find('.td-quantity-button.min');
        let $btn_plus = $qty.find('.td-quantity-button.plus');
        let $text_input = $qty.find('.input-text');

        $btn_minus.on("click", update_qty.bind(this, i));
        $btn_plus.on("click", update_qty.bind(this, i));
    })

    // Quantity change via <input>
    $form.on('change', 'input.qty', function () {
        console.log('change')
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(function () {
            update_minicart();
            // $("[name='update_cart']").trigger("click");
        }, 1000);

    });

    // Listen for WC update event
    // jQuery(document.body).on('updated_wc_div', update_cart);

})