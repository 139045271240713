import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName('splide_related_products');

for (var i = 0; i < elms.length; i++) {
	var splide = new Splide(elms[i], {
		perPage: 4,
		gap: 33,
		arrows: true,
		pagination: false,
		breakpoints: {
			768: {
				perPage: 2,
			},
			425: {
				perPage: 1,
			},
		},
	});

	splide.mount();
}
