(function() {
    var key = 'quectel';
    var host_survey = 'https://www.iotreadinesscalculator.org';
    let readinessButton = document.getElementById('iotReadinessCalculator');
    if(readinessButton !== null) {
        readinessButton.addEventListener('click', function () {
            var iframe = document.createElement('iframe');
            iframe.id = 'iotrc-iframe';
            iframe.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;z-index: 999999';
            iframe.src = host_survey + '/survey/' + key + '.html?iframe=true';
            document.body.appendChild(iframe);
            document.body.style.overflowY = "hidden";
        });
        window.addEventListener('message', function (event) {
            if (event.data == 'iotrc-iframe-close') {
                var iframe = document.getElementById('iotrc-iframe');
                iframe.parentElement.removeChild(iframe);
                document.body.style.overflowY = "visible";
            }
        }, false);
    }
}());