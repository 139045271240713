import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName('splide_slider_text_image');

for (var i = 0; i < elms.length; i++) {
	var splide = new Splide(elms[i], {
		type: 'loop',
		perPage: 1,
		gap: 20,
		pagination: true,

	}).mount();

	var el = elms[i];  
	splide.on( 'move', function (newIndex,prevIndex,destIndex) {
		console.log(el);
		let imgs = el.closest('.block_split_carousel').querySelectorAll('.main-image img');

		let i = 0;
		while (i < imgs.length) {
			imgs[i].classList.remove('on');
			if(i == newIndex){
				imgs[i].classList.add('on');
			}
			i++;
		}
	} );    
}  
