jQuery(document).ready(() => {
    console.log();
})

function search_widget() {
    let $ = jQuery;
    let $clicker = $('.q-widget-search')
    let $searchform = $('.search-form')
    let $closeButton = $searchform.find('.close-button')
    $clicker.click((event) => {
        event.preventDefault();
        $searchform.toggleClass('active');
    })
    $closeButton.click((event) => {
        event.preventDefault();
        $searchform.removeClass('active');
    })
}

search_widget();