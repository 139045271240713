const downloadsAccordionAll = document.querySelectorAll('.downloads-accordion');

if (downloadsAccordionAll.length > 0) {
  const cls = 'expanded';

  Array.from(downloadsAccordionAll).map(acc => {
    const sections = acc.querySelectorAll('[data-accordion-element=section]');

    Array.from(sections).map(section => {
      section.querySelector('button').addEventListener('click', function() {
        this.parentElement.classList.toggle(cls);
      });
    })
  })
}