import { tns } from "tiny-slider/src/tiny-slider";

let tnsCss = false;

export default () => {
  const sidebarCarousels = document.querySelectorAll('.page_sidebar .carousel_init');
  const topicsCarousel = document.querySelectorAll('.library_topics .library_topics-slider');
  const latestCarousel = document.querySelector('.latest .latest-slider');

  const loadCss = () => {
    if (tnsCss === false) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.href = 'https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.3/tiny-slider.css';
      document.head.appendChild(link);
      tnsCss = true;
    }
  };

  // Sidebar carousels
  sidebarCarousels.forEach((carousel) => {
    loadCss();

    const sidebarSlider = tns({
      container: carousel,
      items: 1,
      slideBy: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      controls: false,
      navPosition: 'bottom',
      gutter: 10,
    });
  });

  topicsCarousel.forEach((carousel) => {
    loadCss();

    const topicsSlider = tns({
      container: carousel,
      items: 1,
      slideBy: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      controls: false,
      navPosition: 'bottom',
      gutter: 10,
    });
  });
  
  // Latest resources slider
  if (latestCarousel) {
    loadCss();
    const latestSlider = tns({
      container: latestCarousel,
      items: 1,
      autoplay: false,
      controls: false,
      gutter: 40,
      navPosition: 'bottom',
      loop: false,
      responsive: {
        768: {
          items: 2,
        },
        960: {
          items: 3,
        },
      },
    });
  }

};