import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default () => {
  const isPage = document.querySelector('.page-template-template-strategic-partners');

  if (isPage) {
    const parentCls = 'sp_categories';
    const animClickConfig = {
      duration: 0.45,
      ease: 'power3.inOut',
    };
    const animOpen = {
      duration: animClickConfig.duration,
      ease: animClickConfig.ease,
      height: 'auto',
    };
    const animClose = {
      duration: animClickConfig.duration,
      ease: animClickConfig.ease,
      height: 0,
    };

    // Reveal content on click
    const moreContentClick = () => {
      const allButtons = document.querySelectorAll(`.${parentCls}-button button`);

      // More / Less messaging toggle
      const toggleMessaging = (options) => {
        const attr = 'data-toggle-text';
        const oldMessage = options.target.innerHTML;
        const newMessage = options.target.getAttribute(attr);
        options.target.setAttribute(attr, oldMessage);
        options.target.innerHTML = newMessage;
      }

      // Click event for reveal buttons
      Array.from(allButtons).map((btn) => {
        let isOpen = false;

        btn.addEventListener('click', function() {

          if(this.parentElement.classList.contains('half')){
            if (isOpen == false) {
              // Open animation
              gsap.to(this.parentElement.parentElement.parentElement.parentElement.querySelector(`.${parentCls}-body`), animOpen);
              this.parentElement.parentElement.querySelector('.text-eyebrow a').style.borderRadius = '0';
              isOpen = true;
            } else {
              // Close animation
              gsap.to(this.parentElement.parentElement.parentElement.parentElement.querySelector(`.${parentCls}-body`), animClose);
              this.parentElement.parentElement.querySelector('.text-eyebrow a').style.borderRadius = '0 0 5px 0';
              isOpen = false;
            }
          } else {
            if (isOpen == false) {
              // Open animation
              gsap.to(this.parentElement.parentElement.parentElement.querySelector(`.${parentCls}-body`), animOpen);
              isOpen = true;
            } else {
              // Close animation
              gsap.to(this.parentElement.parentElement.parentElement.querySelector(`.${parentCls}-body`), animClose);
              isOpen = false;
            }
          }

          // Change 'More' / 'Less' messaging
          toggleMessaging({ target: this });
        });
      });
    }
    moreContentClick();

  };
};