import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName('splide_slider_testimonial');

for (var i = 0; i < elms.length; i++) {
	var splide = new Splide(elms[i], {
		perPage: 1,
		gap: 60,
		arrows: false,
		type    : 'loop',
		autoplay : true,
		pagination: true,
	});

	splide.mount();
}
