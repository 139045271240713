import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName('splide_slider_double');

for (var i = 0; i < elms.length; i++) {
	new Splide(elms[i], {
		perPage: 2,
		gap: 33,
		pagination: false,

		breakpoints: {
			640: {
				perPage: 1,
			},
		},
	}).mount();
}
