var wechatShareIcon = document.querySelectorAll('.qr-click');
if (wechatShareIcon.length > 0) {
  for (var i = 0; i < wechatShareIcon.length; i++) {
    var button = wechatShareIcon[i];
    var cls = 'active';
    
    button.addEventListener('click', function() {
      this.classList.toggle(cls);
      this.nextElementSibling.classList.toggle(cls);
    });
    button.nextElementSibling.addEventListener('click', function() {
      this.classList.remove(cls);
      this.previousElementSibling.classList.remove(cls);
    })
  }
}