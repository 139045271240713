/*
 * Masterclass script
 * */
import Splide from '@splidejs/splide';

// Filters
function masterclass_filters() {

    /* SVG Icon Anim */
    const $svg = $('#filterSwitch svg');
    const $circles = $('#filterSwitch svg circle');
    $svg.hover(() => {
        // Top circle
        gsap.to($circles[0], {duration: .5, x: -10, ease: "power1.out"});
        // Bottom circle
        gsap.to($circles[1], {duration: .5, x: 17, ease: "power1.out"});
    },() => {
        // Top circle
        gsap.to($circles[0], {duration: .5, x: 0, ease: "power1.out"});
        // Bottom circle
        gsap.to($circles[1], {duration: .5, x: 0, ease: "power1.out"});
    })

    const openFilters = document.querySelectorAll('.open-mc-filter');
    const appliedFilterDiv = document.getElementById('appliedFilterDiv');
    const filterBtns = document.querySelectorAll('#appliedFilterDiv button');
    const appliedFiltersContainer = document.getElementById('appliedFiltersContainer');
    const labelSelect = document.querySelectorAll('.select-label');
    const termSelect = document.querySelectorAll('.selections-container div');
    let postItems = document.querySelectorAll('.masterclass-item:not(.hidden)');
    const postGrid = document.getElementById('postGrid');
    const searchInput = document.getElementById('searchInput');
    const noResults = document.getElementById('noResults');
    const pagination = document.querySelector('.mc-pagination-wrapper');
    const filterSwitch = document.getElementById('filterSwitch');
    const filterWrap = document.querySelector('.mc-filter-wrap');
    const closeMcFilter = document.getElementById('closeMcFilter');
    const filtersLength = document.getElementById('filtersLength');
    let filtersSelected = [];

    var options = {
        valueNames: [
            'post__title',
            { data: ['region', 'technology']}
        ],
        page: 6,
        pagination: {
            paginationClass: "mc-pagination",
        }
    };

    // masterClassList
    let list = document.querySelectorAll('.masterclass-post-grid-wrapper')[0]
    var masterClassList = new List(list, options);

    masterClassList.on('updated', function(list){
        //console.log(list.matchingItems.length);
        AOS.refresh();
        filtersLength.innerText = filtersSelected.length;
        if (list.matchingItems.length > 6 ) {
            pagination.classList.remove('hide');
            noResults.classList.remove('show');
        } else {
            pagination.classList.add('hide');
            if ( list.matchingItems.length === 0 ) {
                noResults.classList.add('show');
            } else {
                noResults.classList.remove('show');
            }
        }
        postGrid.querySelectorAll('li').forEach(function(li) {
            li.style.opacity = 0;
            setTimeout(function() {
                li.style.opacity = 1;
            }, 100)
        })
        document.body.scrollTop = 670;
        document.documentElement.scrollTop = 670;
    });

    if (filterSwitch) {
        filterSwitch.addEventListener('click', function(e){
            e.preventDefault();
            filterWrap.classList.add('show');
        });
    }

    if (closeMcFilter) {
        closeMcFilter.addEventListener('click', function(e){
            e.preventDefault();
            filterWrap.classList.remove('show');
        });
    }

    function technologiesSelected() {
        let technologies = [];
        filtersSelected.forEach( function(select, index){
            if ( select.filter === 'technology' ) {
                technologies.push( select.value );
            }
        });
        return technologies;
    }

    function regionsSelected() {
        let regions = [];
        filtersSelected.forEach( function(select, index){
            if ( select.filter === 'region' ) {
                regions.push( select.value );
            }
        });
        return regions;
    }

    openFilters.forEach(function(filter){
        filter.addEventListener('click', function(e){
            e.preventDefault();
            this.classList.toggle('open');
            let filterId = this.dataset.filter;
            document.getElementById(filterId).classList.toggle('hide');
        });
    });

    function generateAppliedFilters() {
        appliedFilterDiv.innerHTML = "";
        filtersSelected.forEach(function(filter){
            const button = document.createElement('a');
            button.innerHTML = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.69653" y="11.6531" width="2.54595" height="14.6392" transform="rotate(-131.785 1.69653 11.6531)" fill="white"/>
<rect x="0.259521" y="1.8985" width="2.54595" height="14.6392" transform="rotate(-45 0.259521 1.8985)" fill="white"/>
</svg>${filter.label}`;
            button.setAttribute('data-value', filter.value);
            button.setAttribute('data-filter', filter.filter);
            button.addEventListener('click', function(e) {
                e.preventDefault();
                filtersSelected = filtersSelected.filter((item) => item.value != filter.value);
                document.querySelector('.selections-container div[data-value="'+ filter.value + '"]').classList.remove('hide');
                generateAppliedFilters( filtersSelected );
                hideFilterSelectedDiv();
                searchUpdate();
            });
            appliedFilterDiv.appendChild(button);
            setTimeout(function() {
                button.classList.add('show');
            }, 100);
        });
    }

    filterBtns.forEach(function(button) {
        button.addEventListener('click', function(event) {
            event.preventDefault();
            alert(this.dataset.value);
        })
    });

    labelSelect.forEach(function(label){
        label.addEventListener('click', function(event) {
            event.preventDefault();
            let selector = this.dataset.filter;
            document.getElementById(selector).classList.toggle('hide');
        })
    });

    document.addEventListener('click', function(event) {
        var isClickInside = filterWrap.contains(event.target);

        if (!isClickInside) {
            document.getElementById('technology-select').classList.add('hide');
            document.getElementById('region-select').classList.add('hide');
        }
    });

    termSelect.forEach(function(term){
        term.addEventListener('click', function(e){
            e.preventDefault();
            this.classList.add('hide');
            let btnfilter = this.dataset.filter,
                btnvalue = this.dataset.value,
                btnLabel = this.dataset.label;
            filtersSelected.push(
                {
                    value: btnvalue,
                    filter: btnfilter,
                    label: btnLabel,
                }
            );

            const button = document.createElement('a');
            button.innerHTML = `<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="1.69653" y="11.6531" width="2.54595" height="14.6392" transform="rotate(-131.785 1.69653 11.6531)" fill="white"/>
				<rect x="0.259521" y="1.8985" width="2.54595" height="14.6392" transform="rotate(-45 0.259521 1.8985)" fill="white"/>
				</svg>${btnLabel}`;
            button.setAttribute('data-value', btnvalue);
            button.setAttribute('data-filter', btnfilter);

            button.addEventListener('click', function(e) {
                e.preventDefault();
                filtersSelected = filtersSelected.filter((item) => item.value != btnvalue );
                document.querySelector('.selections-container div[data-value="'+ btnvalue + '"]').classList.remove('hide');
                button.classList.remove('show');
                setTimeout(function() {
                    button.remove();
                }, 300);
                hideFilterSelectedDiv();
                searchUpdate();
            });

            document.getElementById('technology-select').classList.add('hide');
            document.getElementById('region-select').classList.add('hide');

            appliedFilterDiv.appendChild(button);
            setTimeout(function() {
                button.classList.add('show');
            }, 100);

            searchUpdate();
            hideFilterSelectedDiv();
            //generateAppliedFilters();
        });
    });

    function hideFilterSelectedDiv() {
        if ( filtersSelected.length > 0 ) {
            appliedFiltersContainer.classList.remove('hide');
        } else {
            if (appliedFiltersContainer) {
                appliedFiltersContainer.classList.add('hide');
            }
        }
    }

    function findItemsByFilter( filter ){
        document.querySelectorAll('.masterclass-item', function( item ){

        });
    }

    function searchUpdate() {
        masterClassList.filter(function (item) {
            return (regionsSearch(item) && technologiesSearch(item));
        });
        masterClassList.update();
    }

    function keywordSearch( element ) {
        let title = element.querySelector('.post__title').textContent;
        let value = searchInput.value.toLowerCase().replace(/  +/g, ' ').trim();

        if ( value ) {
            if ( title.toLowerCase().includes(value) ) {
                return true;
            } else {
                return false;
            }

        } else {
            return true;
        }
    }

    function regionsSearch( item ) {

        let regionsSelect = regionsSelected(),
            elementRegions = item.values().region;

        if ( regionsSelect.length === 0 ) {
            return true;
        }  else if ( elementRegions == null ) {
            return false;
        } else {
            let elementRegionsArray = elementRegions.split(',');
            return hasCommonElement(regionsSelect, elementRegionsArray);
        }
    }

    function technologiesSearch( item ) {
        let technologiesSelect = technologiesSelected(),
            elementTechnologies = item.values().technology;

        if ( technologiesSelect.length === 0 ) {
            return true;
        }  else if ( elementTechnologies == null ) {
            return false;
        } else {
            let elementTechnologiesArray = elementTechnologies.split(',');
            return hasCommonElement(technologiesSelect, elementTechnologiesArray);
        }
    }

    function isBlankKeyword(str){
        return str === null || str.match(/^\s*$/) !== null;
    }

    function hasCommonElement(arr1, arr2) {
        return arr1.some(item => arr2.includes(item))
    }

    //generateAppliedFilters();
    hideFilterSelectedDiv();
}

function masterclass_speaker_carousel() {
    // Viewport size
    let breakpoint = 920;
    let mobile = window.innerWidth < breakpoint;
    let slideCount = document.querySelectorAll('ul.splide__list li').length
    const colCount = 3;
    const gap = 20;
    // Mount a slider
    function mount() {
        if(slideCount<=3) {
            return new Splide('.splide', {
                perPage: mobile ? 1 : 3,
                clones: mobile ? 2 : 0,
                fixedWidth: mobile ? "100%" : (100 / slideCount) + "%",
                width: mobile ? "100%" : ((100 / colCount) * slideCount) + "%",
                // gap: gap + "px",
            }).mount();
        } else {
            return new Splide('.splide', {
                perPage: mobile ? 1 : 3,
                clones: mobile ? 2 : 0,
                width: mobile ? "100%" : ((100 / colCount) * slideCount) + "%",
                // gap: gap + "px",
            }).mount();
        }
    }
    // On resize
    function resized() {
        mobile = window.innerWidth < breakpoint;
        carousel.destroy()
        carousel = mount()
    }
    // Attach resize listener
    var timer;
    window.onresize = function(){
        clearTimeout(timer);
        timer = setTimeout(resized, 5);
    };

    // Init
    let carousel = mount()
}

jQuery(document).ready(function($) {
    // If body class contains masterclass
    if (
        document.body.className.indexOf('page-template-template-archive-masterclass-events') > -1
        || document.body.className.indexOf('page-template-template-archive-masterclass-library') > -1
    ) {
        masterclass_filters()
    }
    if (
        document.body.className.indexOf('single-masterclass-event') > -1
        || document.body.className.indexOf('single-masterclass-library') > -1
    ) {
        masterclass_speaker_carousel()
    }
});
