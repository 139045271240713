import gsap from "gsap";

export default () => {
  const eventsModalConfig = {
    dur: 0.3,
    delay: 0.15,
  };

  // Close modal
  const closeModal = (options) => {
    gsap.to(`[quectel-modal="${options.modalId}"]`, {
      autoAlpha: 0,
      delay: eventsModalConfig.delay,
      duration: eventsModalConfig.dur,
      display: 'none',
    });
  };

  const eventsModal = () => {
    const openBtns = document.querySelectorAll('[quectel-modal-open] button');
    const closeBtns = document.querySelectorAll('[quectel-modal-close]');
    const readMoreBtns = document.querySelectorAll('.speaker_modal-more button');

    let isExpanded = false;
    let defaultMessage;
    const expandedMessage = 'Hide text';
    
    function accordion(options) {
      if (options.reset) {
        gsap.set(document.querySelector(`[quectel-modal="${options.modalId}"] .speaker_modal-biography_continued`), {
          delay: 1,
          duration: eventsModalConfig.dur,
          height: 0,
          onComplete: () => {
            document.querySelector(`[quectel-modal="${options.modalId}"] .speaker_modal-more button`).innerHTML = defaultMessage;
            isExpanded = false;
          },
        });
      } else {
        // console.log(options.modalId)
        if (!isExpanded) {
          gsap.to(document.querySelector(`[quectel-modal="${options.modalId}"] .speaker_modal-biography_continued`), {
            duration: eventsModalConfig.dur,
            height: 'auto',
          });
          document.querySelector(`[quectel-modal="${options.modalId}"] .speaker_modal-more button`).innerHTML = expandedMessage;
          isExpanded = true;
        } else {
          gsap.to(document.querySelector(`[quectel-modal="${options.modalId}"] .speaker_modal-biography_continued`), {
            duration: eventsModalConfig.dur,
            height: 0,
          });
          document.querySelector(`[quectel-modal="${options.modalId}"] .speaker_modal-more button`).innerHTML = defaultMessage;
          isExpanded = false;
        }
      }
    };

    // Reveal modal
    openBtns.forEach(btn => {
      btn.addEventListener('click', function() {
        const parent = this.parentElement.parentElement;
        const modalId = parent.getAttribute('quectel-modal-open');

        gsap.to(`[quectel-modal="${modalId}"]`, {
          autoAlpha: 1,
          duration: eventsModalConfig.dur,
          display: 'block',
        });
        gsap.from(`[quectel-modal="${modalId}"] .speaker_modal-window`, {
          autoAlpha: 0,
          delay: eventsModalConfig.delay,
          duration: eventsModalConfig.dur,
          yPercent: 10,
        });
        gsap.from(`
          [quectel-modal="${modalId}"] .speaker_modal-info *,
          [quectel-modal="${modalId}"] .speaker_modal-body_title h5,
          [quectel-modal="${modalId}"] .speaker_modal-biography *,
          [quectel-modal="${modalId}"] .speaker_modal-more *
        `, {
          autoAlpha: 0,
          delay: eventsModalConfig.delay * 2,
          duration: eventsModalConfig.dur,
          yPercent: 70,
          stagger: eventsModalConfig.dur / 3,
        });
      })
    });

    // Close modal
    closeBtns.forEach(btn => {
      btn.addEventListener('click', function() {
        closeModal({ modalId: this.getAttribute('quectel-modal-close') });
        accordion({
          modalId: this.getAttribute('quectel-modal-close'),
          reset: true
        });
      });
    });

    // Read more accordion
    readMoreBtns.forEach(btn => {
      defaultMessage = btn.innerHTML;
      btn.addEventListener('click', function() {
        accordion({
          modalId: this.parentElement.parentElement.parentElement.parentElement.parentElement.getAttribute('quectel-modal'),
          reset: false
        });
      });
    });
  };

  
  
  // Read more functionality
  const readMore = (reset = false) => {
    const btns = document.querySelectorAll('.speaker_modal-more button');
    btns.forEach(btn => {
      let isExpanded = false;
      const defaultMessage = btn.innerHTML;
      const expandedMessage = 'Hide text';
      
      function expand(el) {
        gsap.to(el.parentElement.parentElement.querySelector('.speaker_modal-biography_continued'), {
          duration: eventsModalConfig.dur,
          height: 'auto',
        });
        el.innerHTML = expandedMessage;
        isExpanded = true;
      };
      
      function contract(el) {
        gsap.to(el.parentElement.parentElement.querySelector('.speaker_modal-biography_continued'), {
          duration: eventsModalConfig.dur,
          height: 0,
        });
        el.innerHTML = defaultMessage;
        isExpanded = false;
      };
      
      function resetBio(el) {
        gsap.set(el.parentElement.parentElement.querySelector('.speaker_modal-biography_continued'), {
          duration: eventsModalConfig.dur,
          height: 0,
        });
        el.innerHTML = defaultMessage;
        isExpanded = false;
      };
      
      if (reset === 'reset') {
        console.log('reset');
        btn.addEventListener('click', function() {
          resetBio(this);
        });
      }
      
      if (reset !== 'reset') {
        console.log('not reset');
        btn.addEventListener('click', function() {
          if (!isExpanded) {
            expand(this);
          } else {
            contract(this);
          }
        });
      }
    });
  };

  // Run scripts when page is ready
  window.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('load', () => {
      eventsModal();
    });
  });
};