document.addEventListener('DOMContentLoaded', function() {

  var ctas = document.querySelector('.woocommerce-grouped-product-list-item__label');
  return;
  var groupedForm = document.querySelector('.grouped_form');
  if (groupedForm) {
    var productBtn = groupedForm.querySelectorAll('.woocommerce-grouped-product-list-item__label .btn');
    var quantitySelector = document.querySelector('.quantity input[type=text]');
    var quantitySelectorValue = quantitySelector.value;
    var productCheckboxes = groupedForm.querySelectorAll('.wc-grouped-product-add-to-cart-checkbox');
    var cls = 'selected';

    // set defaults on load
    productBtn[0].classList.add(cls);
    productBtn[0].nextElementSibling.checked = true;
    productBtn[0].nextElementSibling.value = quantitySelector.value;

    // toggle active product checkbox
    function toggleCheckbox(elToActivate) {
      for (var i = 0; i < productBtn.length; i++) {
        const el = productBtn[i];
        el.nextElementSibling.checked = false;
      }
      elToActivate.checked = true;
    };

    // set styling for active product button
    function styleBtns(elToStyle) {
      for (var i = 0; i < productBtn.length; i++) {
        const el = productBtn[i];
        el.classList.remove(cls);
      }
      elToStyle.classList.add(cls);
    };

    // update checkbox values
    function updateCheckboxes() {
      console.log(productCheckboxes);
      for (var i = 0; i < productCheckboxes.length; i++) {
        var el = productCheckboxes[i];
        console.log(el, quantitySelectorValue);
        el.value = quantitySelectorValue;
      }
    };

    // incriment values on quantity increase
    groupedForm.querySelector('.td-quantity-button.plus').addEventListener('click', function() {
      if (quantitySelectorValue >= 0) {
        quantitySelectorValue++;
      }
      updateCheckboxes();
    });
    
    // decriment values on quantity decrease
    groupedForm.querySelector('.td-quantity-button.min').addEventListener('click', function() {
      if (quantitySelectorValue > 0) {
        quantitySelectorValue--;
      }
      updateCheckboxes();
    });

    function selectProduct() {
      for (var i = 0; i < productBtn.length; i++) {
        const el = productBtn[i];
        el.addEventListener('click', function() {
          toggleCheckbox(this.nextElementSibling);
          styleBtns(this);
        })
      }
    }
    selectProduct();
  }
});

const nquery = jQuery;
let slideWrapper = nquery('.nickx-slider-for');
let next_img = document.getElementById('videoThumbnailNextPage');
if(next_img){
  next_img.onclick = function (){
    slideWrapper.nslick('nslickNext');
  }
}
