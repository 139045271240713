import * as Add2Calendar from "add2calendar"

// let tnsCss = false;

export default () => {
    const buttons = document.querySelectorAll('.cal-item');

    // Abort if no links exist
    if (buttons.length === 0) {
        return
    }

    // Event data + calendar class
    const event_data = window.event_data
    const add2cal = new Add2Calendar(event_data);

    buttons.forEach((elem, i) => {
        // Calendar type
        const calType = elem.dataset.calType
        // Click handler
        elem.addEventListener('click', (e) => {
            e.preventDefault()
            switch (calType) {
                case 'google':
                    window.open(add2cal.getGoogleUrl(), '_blank').focus();
                    break;
                case 'ical':
                    window.open(add2cal.getICalUrl(), '_blank').focus();
                    break;
                case 'outlook':
                    window.open(add2cal.getOutlookUrl(), '_blank').focus();
                    break;
                case 'ics':
                    window.open(add2cal.getICalUrl(), '_blank').focus();
                    break;
                default:
                    break;
            }
        })
    })
};