function recruitmentTemplate() {
  
  this.qsRegex = null;

  // Prepare item counts
  this.items = document.querySelectorAll(this.options.itemSelector)
  this.activeFilters = [];
  this.searchString = "";

  // Masonry
  this.options.isotope = $(this.options.container).isotope({
    itemSelector: this.options.itemSelector,
    masonry: {
      gutter: 14,
      columnWidth: this.options.itemSelector
    },
  })

  // Init
  this.buildIndex()
  this.setupTextSearch()
  this.setupFilters()
  this.paginate()
  this.attachResizeHandler()

  // Show
  $(this.options.container).css('opacity', 1)

  return this;
}

recruitmentTemplate.prototype.options = {
  isotope: null,
  container: '.recruitment-content_row',
  itemSelector: '.recruitment-item',
  filterContainer: '.filter-button-group',
  paginationContainer: '.post-grid__pagination',
  textSearchContainer: '.recruitment-search',
  itemsPerPage: 9,
}

recruitmentTemplate.prototype.breakpoints = {
  // <breakpoint> : <row count>
  /* X-Large (x4) */
  'screen and (min-width: 1920px)': 12,
  /* Large (x3) */
  'screen and (min-width: 1440px) and (max-width: 1919px)': 9,
  /* Medium (x2) */
  'screen and (min-width: 1024px) and (max-width: 1439px)': 8,
  /* Small (x1) */
  "screen and (max-width: 1023px)": 9,
}

recruitmentTemplate.prototype.buildIndex = function() {
  this.index = $(this.items).map((i, elem) => {
    return {
      element : elem,
      keywords : $(elem).data("keywords"),
    }
  })
  this.filtered = this.index;
}

recruitmentTemplate.prototype.setupTextSearch = function() {
  const self = this;
  const $input = $(this.options.textSearchContainer).find('input')
  var $quicksearch = $input.keyup(this.debounce(() => {
    let str = $input.val()
    this.searchString = str;
    this.applyFilter()
  }, 200).bind(this));
}

recruitmentTemplate.prototype.debounce = function(fn, threshold) {
    var timeout;
    threshold = threshold || 100;
    return function debounced() {
      clearTimeout( timeout );
      var args = arguments;
      var _this = this;
      function delayed() {
        fn.apply( _this, args );
      }
      timeout = setTimeout( delayed, threshold );
    };
}

recruitmentTemplate.prototype.setupFilters = function() {
  const $container = $(this.options.filterContainer)
  const $filters = $container.find('button')
  $filters.each((i, item) => {
    let $item = $(item)
    $item.click((e) => {
      e.preventDefault()
      let $elem = $(e.target);
      let term = $elem.data('filter');
      $elem.toggleClass('active')
      if ($elem.hasClass('active')) {
        this.activeFilters.push(term)
      } else {
        var index = this.activeFilters.indexOf(term);
        this.activeFilters.splice(index, 1);
      }
      this.applyFilter()
    })
  })
}

recruitmentTemplate.prototype.applyFilter = function() {
  
  let filtered = [];

  // Reset classes
  $(this.items).removeClass('selected')
  
  // Reset if filters + search are empty
  if (this.activeFilters.length == 0) {
    filtered = Array.prototype.slice.call( this.index )
  } else {
    // Filter by selected filters
    for (let i = 0; i < this.index.length; i++) {
      let item = this.index[i];
      const found = this.activeFilters.some(r=> item.keywords.indexOf(r) >= 0)
      if (found) {
        filtered.push(item)
      }
    }
  }

  // Filter by search input
  if (this.searchString !== "") {
    const arr = []
    for (let i = 0; i < filtered.length; i++) {
      let item = filtered[i];
      let keywords = item.keywords.toLowerCase();
      if (keywords.indexOf(this.searchString.toLowerCase()) > -1) {
        arr.push(item)
      }
    }
    filtered = arr;
  }

  this.filtered = filtered;

  // Apply selected class
  for (let i = 0; i < filtered.length; i++) {
    const item = filtered[i];
    $(item.element).addClass('selected')
  }

  // Chunk check for pagination
  this.paginate()
}

recruitmentTemplate.prototype.paginate = function() {
  
  const paginationContainer = document.querySelectorAll(this.options.paginationContainer)
  let selected = this.filtered;
  let pageCount = parseInt(selected.length / this.options.itemsPerPage)
  let remainder = selected.length % this.options.itemsPerPage
  this.pageCount = pageCount + (remainder > 0 ? 1 : 0);
  this.currentPage = 1;

  // Build pagination element
  var html = '<div class="post-grid__pagination-wrapper">';
  for (let i = 1; i <= this.pageCount; i++) {
    if (i == 1) {
      html += '<a href="#" class="page-numbers current" data-page="' + i + '">' + i + '</a>';
    } else {
      html += '<a href="#" class="page-numbers" data-page="' + i + '">' + i + '</a>';
    }
  }
  // html += (this.pageCount > 1 ? '<a href="#" class="next page-numbers">→</a>' : '')
  html += '</div>'
  $(paginationContainer).html(this.pageCount > 1 ? html : '')

  const buttons = $(this.options.paginationContainer).find('.page-numbers')
  $(buttons).click((e) => {
    e.preventDefault()
    $(buttons).removeClass('current')
    $(e.target).addClass('current')

    const isNext = $(e.target).hasClass('next')
    let targetPage = $(e.target).data('page')
    const target = isNext ? (this.currentPage == this.pageCount ? this.pageCount : this.currentPage + 1 ) : targetPage;
    this.goToPage(target)
  })

  // Chunk items into pages
  this.pages = this.chunk(selected, this.options.itemsPerPage)

  this.goToPage(1)
}

recruitmentTemplate.prototype.chunk = function(arr, chunkSize) {
  if (chunkSize <= 0) throw "Invalid chunk size";
  var R = [];
  for (var i=0,len=arr.length; i<len; i+=chunkSize)
    R.push(arr.slice(i,i+chunkSize));
  return R;
}

recruitmentTemplate.prototype.goToPage = function(pageNum) {
  for (let i = 0; i < this.items.length; i++) {
    const element = this.items[i];
    $(element).removeClass('visible')
  }
  if (this.pages[pageNum-1] == undefined) {
    $(this.options.itemSelector).removeClass('visible')
    $('.recruitment-no_results').addClass('show')
  } else {
    $('.recruitment-no_results').removeClass('show')
    for (let i = 0; i < this.pages[pageNum-1].length; i++) {
      const element = this.pages[pageNum-1][i].element;
      $(element).addClass('visible')
    }
  }

  // Run isotope filter
  this.options.isotope.isotope({ filter: '.visible' });
}

recruitmentTemplate.prototype.attachResizeHandler = function() {
  $(window).on('resize', this.debounce(() => {
    var win = $(window);
    const result = '';
    for (const key in this.breakpoints) {
      const count = this.breakpoints[key];
      const mediaQuery = key;
      if (window.matchMedia(mediaQuery).matches) {
        this.options.itemsPerPage = count;
        this.paginate()
      }
    }
  }, 200).bind(this));
}

/* 
 * Initialise
 */
window.addEventListener('DOMContentLoaded', function(event) {
  var isPage = document.querySelector('.page-template-template-archive-recruitment');
  if (isPage) {
    new recruitmentTemplate()
  }
});