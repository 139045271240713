import gsap from "gsap";

export default () => {
  const accordionFilterControls = document.querySelector('[data-ui-type=accordion-filter-controls]'),
    accordionFilterBody = document.querySelector('[data-ui-type=accordion-filter-body]'),
    hasAccordionFilterControls = accordionFilterControls ? true : false,
    hasAccordionFilterBody = accordionFilterBody ? true : false,
    animConfig = {
      dur: 1,
      scale: 0,
      autoAlpha: 0,
      ease: 'power3.inOut',
    };

  const accordionFilter = () => {
    const filterBtns = accordionFilterControls.querySelectorAll('.btn');
    let filterData = [];
    let activeFilters = [];

    // store data about current state of each filtered section
    const updateState = (arrayIndex) => filterData[arrayIndex].active = filterData[arrayIndex].active == true ? false : true;

    // style filter control button and filtered section with class toggle
    const toggleClass = (options) => {
      options.clickedItem.classList.toggle(options.className);
      options.relatedItem.classList.toggle(options.className);
    };

    // filter control buttons loop
    filterBtns.forEach((btn, index) => {

      // store initial state of each filtered section
      filterData.push({
        active: false,
        filterString: btn.getAttribute('data-filter-category'),
        related: accordionFilterBody.querySelector(`[data-category="${btn.getAttribute('data-filter-category')}"]`),
        target: btn,
      });

      // click event for every filter control button
      btn.addEventListener('click', function() {

        // style active elements
        toggleClass({
          clickedItem: filterData[index].target,
          relatedItem: filterData[index].related,
          className: 'active',
        });

        // update state of filtered section
        updateState(index);

        // if any filter is active, return true, otherwise return false
        activeFilters = filterData.filter(active => active.active == true);

        // if any filter is active, find active filter and hide all other sections
        if (activeFilters.length > 0) {
          activeFilters.forEach(activeData => {
            const unrelated = accordionFilterBody.querySelectorAll(`.sp_categories-category:not(.active)`);

            // hide unrelated sections on first filter selection
            if (unrelated.length > 0) {
              gsap.to(unrelated, {
                duration: animConfig.dur,
                scale: animConfig.scale,
                autoAlpha: animConfig.autoAlpha,
                ease: animConfig.ease,
                height: 0,
              })
            }

            // show related section
            gsap.to(activeData.related, {
              duration: animConfig.dur,
              scale: 1,
              autoAlpha: 1,
              ease: animConfig.ease,
              height: 'auto',
            })


          });
        } else {
          // show all sections on filter deselection
          const allSections = accordionFilterBody.querySelectorAll(`.sp_categories-category[data-category]`);
          gsap.to(allSections, {
            duration: animConfig.dur,
            scale: 1,
            autoAlpha: 1,
            ease: animConfig.ease,
            height: 'auto',
          })
        }
      });

    });
  };

  // Run scripts when page is ready
  window.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('load', () => {

      if (hasAccordionFilterControls && hasAccordionFilterBody) {
        accordionFilter();
      } else {
        if ((hasAccordionFilterControls && !hasAccordionFilterBody) || (!hasAccordionFilterControls && hasAccordionFilterBody)) {
          console.warn('Can`t find elements with attributes [data-ui-type=accordion-filter-controls] and/or [data-ui-type=accordion-filter-body]. The Accordion Filter javascript will not run on this page.')
        }
      }
    });
  });
};