/* 
 * Quectel WP Theme
 * Author: rick@pangolincomms.com
 */

/* 
 * NOTE - Use `jQuery` instead of `$` with WordPress
 */

/* 
 * Nav
 */
require('./generic/nav.js');
require('./generic/search-widget.js');
require('./generic/social-share.js');
require('./generic/smooth-scroll.js');

/* 
 * Shop
 */
require('./shop/minicart.js');
require('./shop/filters.js');
require('./shop/checkout.js');
require('./shop/downloads-accordion.js');
require('./shop/grouped-product.js');

/* 
 * Content
 */
require('./content/tabs.js');
// require('./content/iframe-forms.js');
require('./content/cookie-policy.js');
require('./content/recruitment.js');
require('./content/owl-carousel.js');
require('./content/owl.carousel.min.js');
require('./content/masterclass.js');
require('./content/iot-readiness-calculator.js');
// require('add-to-calendar-button');
require('./content/quec-accordion.js');

// Imports
import accordionFilter from './ui/accordion-filter';
import eventsModal from './ui/events-modal';
import productOverview from './ui/carousel-product_overview';
import strategicPartners from './content/strategic-partners';
import addToCalendar from './ui/addtocalendar';

/*
 * Gutenberg blocks
 */
require('./gutenberg/blocks/example.js');
require('./gutenberg/blocks/slider_double.js');
require('./gutenberg/blocks/slider_related_products.js');
require('./gutenberg/blocks/slider_text_image.js');
require('./gutenberg/blocks/slider_horizontal_post.js');
require('./gutenberg/blocks/slider_testimonial.js');

// Functions
accordionFilter();
eventsModal();
productOverview();
strategicPartners();
addToCalendar();